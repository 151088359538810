<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogViewToDo"
			transition="dialog-top-transition"
			persistent
			width="700"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" :style="`border-bottom: solid 1px var(--color__cinza); background-color: ${toDoResponse.urgent === 1 ? 'var(--color__red); color: #ffffff' : '#ffffff'}`">
					View To Do {{ typeToDoCategory }}
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
		
					<v-row>
						<v-col 
							v-if="toDoResponse != null && toDoResponse.userNameResponsible != null && toDoResponse.userNameResponsible != undefined && toDoResponse.userNameResponsible != ''"
							cols="12"
							lg="6"
							md="12"
							sm="12"
						>
							<label>Assigned To</label>
							<v-text-field
								v-model="toDoResponse.userNameResponsible" 
								readonly
								single-line
								outlined
								dense
							>
							</v-text-field>
						</v-col>
						<v-col 
							v-if="toDoResponse != null && toDoResponse.toDoCategoryDescription != null && toDoResponse.toDoCategoryDescription != undefined && toDoResponse.toDoCategoryDescription != ''"
							cols="12"
							lg="6"
							md="12"
							sm="12"
						>
							<label>Category</label>
							<v-text-field
								v-model="toDoResponse.toDoCategoryDescription" 
								readonly
								single-line
								outlined
								dense
							>
							</v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12"
							lg="6"
							md="12"
							sm="12"
						>
							<label>Title</label>
							<v-text-field
								v-model="toDoResponse.title" 
								readonly
								single-line
								outlined
								dense
							>
							</v-text-field>
						</v-col>
						<v-col cols="12"
							lg="6"
							md="12"
							sm="12"
						>
							<label>Date</label>
							<v-text-field
								v-model="toDoResponse.dateToDoFormatted" 
								readonly
								single-line
								outlined
								dense
							>
							</v-text-field>
						</v-col>
					</v-row>
					<v-row
						v-if="toDoResponse.projectSelected != null && toDoResponse.projectSelected != undefined"
					>
						<v-col 
							cols="12"
							lg="6"
							md="6"
							sm="12"
						>
							<label>Projects</label>
							<v-text-field
								v-model="toDoResponse.projectSelected.description" 
								readonly
								single-line
								outlined
								dense
							>
							</v-text-field>
						</v-col>
						<v-col
							cols="12"
							lg="6"
							md="6"
							sm="12"
						>
							<label>Phase</label>
							<v-text-field
								v-model="toDoResponse.statusSelected.statusDescription" 
								readonly
								single-line
								outlined
								dense
							>
							</v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>Description</label>
							<v-textarea
								v-model="toDoResponse.description"
								readonly
								single-line
								rows="4"
								row-height="30"
								outlined
								dense
							>
							</v-textarea>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonDismiss"
							outlined
							@click="OK()"
						>
							Dismiss
						</v-btn>

						<v-btn
							v-if="toDoResponse.action != null && toDoResponse.action != undefined && toDoResponse.action != ''"
							class="mx-2"
							outlined
							@click="checkAction"
						>
							Check Action
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';

	export default {

        mixins: [Helpers],

        props: {

			showDialogViewToDo: {
				default: false
			},
			
            id: {
                type: Number,
                default : 0
            },
		},

		
        data: () => ({

			typeToDoCategory: "",

			toDoResponse: {
                id: 0,
                userNameResponsible: "",
                toDoCategoryDescription: "",
                urgent: "0",
                system: "0",
                assignedToMe: "0",
                title: "",
				projectSelected: null,
				statusSelected: null,
                description: "",
                action: ""
            },

			listProjects: []

		}),

        watch: {
            async "id"() {
				await this.getLists();
				this.loadToDo();
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
        },

        methods: {

			async getLists() {
				this.listProjects = await this.$store.dispatch("serviceModule/ListProjectCompact", '-');
			},

			async checkAction() {

				const action = this.toDoResponse.action.toString().split("|");
				const routeName = action[0];

				if (action.length == 1) {
					this.$router.push({ name: routeName });
				}
				else if (action.length == 2) {
					this.$router.push({ name: routeName, params: { id: action[1] } });
				}
				else if (action.length == 3) {
					this.$router.push({ name: routeName, params: { id: action[1], params: action[2] } });
				}
			},

			async loadToDo() {
				
				if (this.id !== 0) {

					this.showLoading();
					
					await this.$store.dispatch("toDoModule/MarkAsReadUnRead", this.id);

					let response = await this.$store.dispatch("toDoModule/GetById", this.id);

					this.toDoResponse = response.result;

					if (this.toDoResponse.urgent === 1) {
						this.typeToDoCategory = " - Urgent"
					}
					else if (this.toDoResponse.system === 1) {
						this.typeToDoCategory = " - System"
					}
					else if (this.toDoResponse.assignedToMe === 1) {
						this.typeToDoCategory = " - Assigned To Me"
					}
					else {
						this.typeToDoCategory = " - " + this.toDoResponse.toDoCategoryDescription
					}

					const idServiceProject = this.toDoResponse.idServiceProject;
                    const projectSelected = this.listProjects.filter(prj => prj.id == idServiceProject)[0];
                    this.toDoResponse.projectSelected = projectSelected;

					this.hideLoading();
				}
			},
			
            async OK() {

                this.$emit('update:showDialogViewToDo', false)
            }
        }
    };
</script>
