<template>
    <div>
        <v-tooltip left v-if="showButtons.edit">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on" 
                    :to="{ name: link, params: { id: id }}"
                >
                    <v-icon small @click="customActionEdit(id)">mdi-pencil</v-icon>
                </v-btn>
            </template>
            <span>Edit</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.delete">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmDelete(id)">mdi-delete</v-icon>
                </v-btn>
            </template>
            <span>Delete</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.cancel">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmCancel(id)">mdi-flask-empty-off</v-icon>
                </v-btn>
            </template>
            <span>Inactive</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.active">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmActive(id)">mdi-account-reactivate-outline</v-icon>
                </v-btn>
            </template>
            <span>Active</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.approve">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmApprove(id)">mdi-account-check-outline</v-icon>
                </v-btn>
            </template>
            <span>Approve</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.resetPassword">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmResetPassword(id)">mdi-lock-reset</v-icon>
                </v-btn>
            </template>
            <span>Reset User Password</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.viewToDo">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="viewToDo(id)">mdi-email-check-outline</v-icon>
                </v-btn>
            </template>
            <span>View To-Do</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.markDoneUnDone">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmDoneUnDone(id)">mdi-check</v-icon>
                </v-btn>
            </template>
            <span>Mark as Done</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.cloneToDo">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmCloneToDo(id)">mdi-content-duplicate</v-icon>
                </v-btn>
            </template>
            <span>Clone this To-Do</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.assignSalesPerson">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="assignSalesPerson(id)">mdi-clipboard-account-outline</v-icon>
                </v-btn>
            </template>
            <span>Assign Sales Person</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.cancel2">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmCancel2(id)">mdi-cancel</v-icon>
                </v-btn>
            </template>
            <span>Cancel</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.markStarted">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmMarkAsStarted(id)">mdi-calendar-start-outline</v-icon>
                </v-btn>
            </template>
            <span>Mark as Started</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.markFailed">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmMarkAsFailed(id)">mdi-thumb-down-outline</v-icon>
                </v-btn>
            </template>
            <span>Mark as Incomplete</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.schedule">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmSchedule(id)">mdi-calendar-start-outline</v-icon>
                </v-btn>
            </template>
            <span>Schedule</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.hold">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmHold(id)">mdi-car-brake-hold</v-icon>
                </v-btn>
            </template>
            <span>On Hold</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.resume">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmResume(id)">mdi-play-circle-outline</v-icon>
                </v-btn>
            </template>
            <span>Resume</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.check">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmCheck(id)">mdi-text-box-check-outline</v-icon>
                </v-btn>
            </template>
            <span>Check</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.download">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmDownload(id)">mdi-file-download-outline</v-icon>
                </v-btn>
            </template>
            <span>Download</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.notes">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmNotes(id)">mdi-chat-alert-outline</v-icon>
                </v-btn>
            </template>
            <span>Communication for the customer</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.payments">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmPayments(id)">mdi-account-credit-card</v-icon>
                </v-btn>
            </template>
            <span>Check Payments</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.viewPaymentsDetails">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="viewPaymentsDetails(id)">mdi-credit-card-settings-outline</v-icon>
                </v-btn>
            </template>
            <span>View Payments Details</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.viewPhotos">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="viewPhotos(id)">mdi-view-gallery-outline</v-icon>
                </v-btn>
            </template>
            <span>View Photos</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.close">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmClose(id)">mdi-check</v-icon>
                </v-btn>
            </template>
            <span>Close</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.view">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmView(id)">mdi-history</v-icon>
                </v-btn>
            </template>
            <span>View</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.addClientConsideration">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmAddClientConsideration(id)">mdi-face-agent</v-icon>
                </v-btn>
            </template>
            <span>Add Client Considerations</span>
        </v-tooltip>

        <ActionResetUserPassword 
            :showDialogResetUserPassword.sync="dialogResetUserPassword.show"
            :id="dialogResetUserPassword.id"
        />

        <ViewToDo 
            :showDialogViewToDo.sync="dialogViewToDo.show"
            :id="dialogViewToDo.id"
        />

        <AssignSalesPerson 
            v-on:methodToCall="dialogAssignSalesPerson.methodUpdate"
            :showDialogAssignSalesPerson.sync="dialogAssignSalesPerson.show"
            :id="dialogAssignSalesPerson.id"
        />
        
    </div>
</template>

<script>
    import ActionResetUserPassword from "@/components/Layout/ActionResetUserPassword";
    import ViewToDo from "@/components/ToDo/ViewToDo";
    import AssignSalesPerson from "@/components/Services/AssignSalesPerson";

    export default {

        components: {
            ActionResetUserPassword: ActionResetUserPassword,
            ViewToDo,
            AssignSalesPerson
        },

        props: {
            link: {
                type: String,
                default: ""
            }, 

            id: {
                type: Number,
                default : 0
            },

            showButtons: {
                type: Object,
                edit: true,
                delete: true,
                cancel: true,
                cancel2: true,
                active: true,
                approve: true,
                resetPassword: true,
                viewToDo: true,
                markDoneUnDone: true,
                cloneToDo: true,
                assignSalesPerson: true,
                download: true,
                markStarted: true,
                markFailed: true,
                schedule: true,
                hold: true,
                resume: true,
                check: true,
                notes: true,
                payments: true,
                viewPaymentsDetails: true,
                viewPhotos: true,
                close: true,
                view: true,
                addClientConsideration: true,
            }
        },

        data: () => ({

            dialogResetUserPassword: {
                show: false,
                id: 0
            },

            dialogViewToDo: {
                show: false,
                id: 0
            },

            dialogAssignSalesPerson: {
                methodUpdate: ( () => {} ),
                show: false,
                id: 0
            },
        }),

        methods: {
            customActionEdit(id) {
                this.$emit("customActionEdit", id);
            },

            confirmDelete(id) {
                this.$emit("confirmDelete", id);
            },

            confirmCancel(id) {
                this.$emit("confirmCancel", id);
            },

            confirmCancel2(id) {
                this.$emit("confirmCancel2", id);
            },

            confirmActive(id) {
                this.$emit("confirmActive", id);
            },

            confirmApprove(id) {
                this.$emit("confirmApprove", id);
            },

            confirmResetPassword(id) {

                this.dialogResetUserPassword = {
                    show: true,
                    id: id
                };
            },

            viewToDo(id) {
                this.dialogViewToDo = {
                    show: true,
                    id: id
                };
                
                this.$emit("viewToDo", id);
            },

            confirmDoneUnDone(id) {
                this.$emit("confirmDoneUnDone", id);
            },

            confirmCloneToDo(id) {
                this.$emit("confirmCloneToDo", id);
            },

            assignSalesPerson(id) {
                this.dialogAssignSalesPerson = {
                    methodUpdate: this.assignSalesPersonBack,
                    show: true,
                    id: id
                };
            },

            assignSalesPersonBack() {
                this.$emit("assignSalesPerson")
            },

            confirmDownload(id) {
                this.$emit("confirmDownload", id);
            },

            confirmMarkAsStarted(id) {
                this.$emit("confirmMarkAsStarted", id);
            },

            confirmMarkAsFailed(id) {
                this.$emit("confirmMarkAsFailed", id);
            },

            confirmSchedule(id) {
                this.$emit("confirmSchedule", id);
            },

            confirmHold(id) {
                this.$emit("confirmHold", id);
            },

            confirmResume(id) {
                this.$emit("confirmResume", id);
            },

            confirmCheck(id) {
                this.$emit("confirmCheck", id);
            },

            confirmNotes(id) {
                this.$emit("confirmNotes", id);
            },

            confirmPayments(id) {
                this.$emit("confirmPayments", id);
            },

            viewPaymentsDetails(id) {
                this.$emit("viewPaymentsDetails", id);
            },

            viewPhotos(id) {
                this.$emit("viewPhotos", id);
            },

            confirmClose(id) {
                this.$emit("confirmClose", id);
            },

            confirmView(id) {
                this.$emit("confirmView", id);
            },

            confirmAddClientConsideration(id) {
                this.$emit("confirmAddClientConsideration", id);
            },
        }
    };
</script>